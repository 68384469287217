import React, { useEffect, useState } from "react"
import Modal from "../Modal"
import { useEarlyAccessStore } from "@/stores"
import { cdnPublicFolderUrl } from "@/constants"
import { FormProvider, useForm, useWatch } from "react-hook-form"
import Input from "../Input"
import IconButton from "../IconButton"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import client from "@/api/client"
import { JoinGaiaEarlyAccessDto } from "@/api/sdk"
import { googleAnalytics } from "@/lib/gtag"
import { useToast } from "@/hooks"
import { motion } from "framer-motion"
import useCustomRouter from "@/hooks/useCustomRouter"
import { isEmailAddress } from "@/utils/is-email-address"

const EarlyAccessModal = () => {
  const { earlyAccess, setEarlyAccess } = useEarlyAccessStore()
  const [isCompleted, setIsCompleted] = useState(false)
  const toast = useToast()

  const form = useForm<JoinGaiaEarlyAccessDto>({
    mode: "onSubmit",
  })

  const {
    setError,
    getValues,
    clearErrors,
    reset,
    register,
    formState: { errors },
  } = form

  const { email } = useWatch({
    control: form.control,
  })

  const onClose = () => {
    setEarlyAccess(false)
    setIsCompleted(false)
    reset()
  }

  const qc = useQueryClient()

  const { mutate, isPending } = useMutation({
    mutationFn: (data: JoinGaiaEarlyAccessDto) =>
      client.api.earlyAccessControllerJoinGaiaEarlyAccess(data).then(res => res.data),
    onSuccess: () => {
      setIsCompleted(true)

      qc.invalidateQueries({ queryKey: ["countEarlyAccess"] })
    },
    onError: (err: any) => {
      toast({
        status: "error",
        title: "Error",
        message: [err?.message || "Something went wrong"],
      })
    },
  })

  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const data = getValues()
    let isValidate = true
    clearErrors()

    googleAnalytics.event({
      action: "click",
      label: "early_access_submit",
      category: "early_access",
      params: {
        email: data.email ?? "",
      },
    })

    if (!isEmailAddress(data.email)) {
      setError("email", {
        message: "Invalid email address",
      })
      isValidate = false
    }
    if (!isValidate) return

    mutate(data)
  }

  const router = useCustomRouter()

  const renderBody = () => {
    if (isCompleted) {
      return (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.35 }}
          className="flex flex-col flex-1"
        >
          <div className="py-6 px-6 md:py-8 md:px-14 w-full flex-1 justify-center flex flex-col">
            <div>
              <h3 className="text-base md:text-xl text-center font-semibold mb-8">
                🚀Congrats 🚀
                <br />
                <br />
                Early access reserved!
                <br />
                Keep an eye out for the magic!
              </h3>
            </div>
            <div className="w-full flex justify-center">
              <IconButton
                onClick={() => {
                  if (router.pathname.includes("/explore")) {
                    onClose()
                  } else {
                    onClose()
                    router.push("/explore")
                  }
                }}
                className="text-lg md:text-xl py-3 px-4 rounded-xl"
              >
                Be Inspired
              </IconButton>
            </div>
          </div>
        </motion.div>
      )
    }

    return (
      <FormProvider {...form}>
        <form className="flex flex-col flex-1" id="early-access" onSubmit={submit}>
          <div className="py-6 px-6 md:py-8 md:px-14 w-full flex-1 justify-center flex flex-col">
            <div>
              <h3 className="text-base md:text-xl text-center font-semibold mb-6">
                Preregister to be among the first to experience GAIA!
              </h3>
              <div className="space-y-4 w-full">
                <div>
                  <Input {...register("email")} placeholder="Your email" className="placeholder:text-atherGray-500" />
                  {errors.email && <p className="text-[0.65rem] text-red-500">{errors.email.message}</p>}
                </div>
                <div>
                  <Input
                    {...register("telegramId")}
                    placeholder="Telegram ID (optional)"
                    className="placeholder:text-atherGray-500"
                  />
                </div>
              </div>
              <div className="mt-8">
                <IconButton disabled={!email} isLoading={isPending} type="submit" className="w-full py-3 rounded-xl">
                  Register
                </IconButton>
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
    )
  }

  return (
    <Modal
      closeButtonClassName="absolute top-2 bg-blackAlpha-600 right-2 z-[1] text-white"
      className="bg-[#191918] rounded-xl max-w-[60rem] h-auto shadow-md p-0 modal-content-child"
      bodyClassName="p-0 justify-center"
      containerClassName={"overflow-auto p-4"}
      isOpen={earlyAccess}
      onClose={onClose}
      showCloseButton={false}
    >
      <div className="flex flex-col md:flex-row">
        <div className="flex-1 flex relative md:h-[40rem] md:bg-atherGray-950">
          <div className="md:p-8 w-full justify-center flex">
            <img
              className="object-contain h-full m-auto hidden md:block"
              src={`${cdnPublicFolderUrl}/images/landing/home/bannerV2.w800.webp`}
              alt="Banner"
            />
            <div className="max-w-[10rem] mb-8 md:mb-12 relative w-full md:w-auto md:absolute top-8 left-8">
              <img src={`${cdnPublicFolderUrl}/logo.png`} alt="Ather Logo" width={88.44} height={44} />
            </div>
          </div>
        </div>
        <div className="flex-1 flex flex-col">{renderBody()}</div>
      </div>
    </Modal>
  )
}

export default EarlyAccessModal
